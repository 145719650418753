import React, { PropsWithChildren, useEffect, useState } from "react";
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { motion, useTime } from "framer-motion";

import styles from "./Home.module.scss";

import * as project from "../components/Project";
import * as Pages from "../Pages";

import LinkedIn from "../images/icons/LinkedIn.png";
import Dribbble from "../images/icons/Dribbble.png";
import Instagram from "../images/icons/Instagram.png";

import ActionIcon from "../vectors/home-action-icon.svg";

import Change from "../images/1_Homepage/1_Recent Work/3_ Change Healthcare Logo.png";
import CodeX from "../images/1_Homepage/1_Recent Work/1_CodeX Logo.png";
import Pear from "../images/1_Homepage/1_Recent Work/2_ Pear Therapeutics Logo.png";

import Agathos from "../images/1_Homepage/2_Project Archive/4_Agathos Cover.png";
import Nuleaf from "../images/1_Homepage/2_Project Archive/5_Nuleaf Cover.png";
import Kheyti from "../images/1_Homepage/2_Project Archive/6_Kheyti Cover.png";
import Sonim from "../images/1_Homepage/2_Project Archive/7_Sonim Cover.png";
import Nexus from "../images/1_Homepage/2_Project Archive/8_Nexus Cover.png";
import { ExternalLink } from "../components/Common";

function TextCarousel() {
    let blurbs = [
        // <p>ONE</p>,
        // <p>TWO</p>,
        <p>cofounded a global tech education nonprofit.</p>,
        <p>am a product design engineer from Stanford.</p>,
        <p>will always get the job done.</p>,
        <p>was born and raised in Hawai'i.</p>,
        <p>knit sweaters in my free time.</p>,
        <p>have been to 36+ countries.</p>,
    ];

    const STAY_FOR = 2;
    const MOVE_FOR = 0.5;
    const EASE = "easeOut";

    const CYCLE_LENGTH = STAY_FOR + MOVE_FOR;

    const clip = (top: number, height: number) =>
        `xywh(0 ${top}% 100% ${height}%)`;

    let animatedElements = blurbs.map((blurb, i) => {
        const delay = i * CYCLE_LENGTH;
        const repeatDelay = CYCLE_LENGTH * (blurbs.length - 1) - MOVE_FOR;

        const keyframes = [
            {
                // start below
                time: 0,
                transform: "translateY(100%)",
                opacity: 0.5,
                clipPath: clip(0, 0),
            },
            {
                // move in
                time: MOVE_FOR,
                transform: "translateY(0)",
                opacity: 1,
                clipPath: clip(0, 100),
            },
            {
                // stay visible
                time: MOVE_FOR + STAY_FOR,
                transform: "translateY(0)",
                opacity: 1,
                clipPath: clip(0, 100),
            },
            {
                // move out
                time: MOVE_FOR + STAY_FOR + MOVE_FOR,
                transform: "translateY(-100%)",
                opacity: 0.5,
                clipPath: clip(100, 0),
            },
            {
                // reset to below
                time: MOVE_FOR + STAY_FOR + MOVE_FOR,
                transform: "translateY(100%)",
                opacity: 0.5,
                clipPath: clip(0, 0),
            },
            {
                // delay
                time: MOVE_FOR + STAY_FOR + MOVE_FOR + repeatDelay,
                transform: "translateY(100%)",
                opacity: 0.5,
                clipPath: clip(0, 0),
            },
        ];

        let { transform, opacity, clipPath, ease, duration, times } = (() => {
            const duration = keyframes[keyframes.length - 1].time;

            let transform: string[] = [];
            let opacity: number[] = [];
            let clipPath: string[] = [];
            let ease: string[] = [];
            let times: number[] = [];

            keyframes.forEach((k) => {
                transform.push(k.transform);
                opacity.push(k.opacity);
                clipPath.push(k.clipPath);
                ease.push(EASE);
                times.push(k.time / duration);
            });

            return { transform, opacity, clipPath, ease, duration, times };
        })();

        console.log({ transform, opacity, clipPath, duration, times });

        return (
            <motion.div
                key={i}
                className={styles.Carousel__Blurb}
                animate={{
                    transform,
                    opacity,
                    clipPath,
                }}
                transition={{
                    duration,
                    delay,
                    times,
                    ease,
                    repeat: Infinity,
                    repeatType: "loop",
                }}
            >
                {blurb}
            </motion.div>
        );
    });

    return (
        <div className={styles.Introduction__Rotating}>{animatedElements}</div>
    );
}

function Introduction() {
    return (
        <div className={styles.Introduction}>
            <div className={styles.Introduction__Content}>
                <div className={styles.Introduction__Left}>
                    <span className={styles.Introduction__Hi}>Hi! </span>
                    I'm Taylor and I{" "}
                </div>
                <div className={styles.Introduction__Right}>
                    <TextCarousel />
                    <div className={styles.Introduction__Static}>
                        <p>
                            On weekdays, I am an award-winning product designer
                            with 8+ years experience in B2B and B2C research,
                            UI/UX, and product management.
                        </p>
                        <p>
                            On weekends, I am a nonprofit cofounder empowering
                            low-resource and conflict-affected children to
                            become programmers, designers, and entrepreneurs.
                            Featured by{" "}
                            <ExternalLink
                                href="https://www.forbes.com/profile/codex/"
                                style="Content"
                            >
                                Forbes
                            </ExternalLink>{" "}
                            and the{" "}
                            <ExternalLink
                                href="https://podcasters.spotify.com/pod/show/world-bank-edutech/episodes/Breaking-Barriers-Fostering-Tech-Skills-in-Emerging-Markets-e2hcdbd"
                                style="Content"
                            >
                                World Bank
                            </ExternalLink>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ActionButton() {
    function scrollBelowFold() {
        const masthead = document.getElementsByTagName("header")[0]!;
        const belowFold = document.getElementById(styles.BelowFold)!;

        const y =
            belowFold.getBoundingClientRect().top -
            masthead.getBoundingClientRect().bottom +
            window.pageYOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
    }

    return (
        <div className={styles.ActionButton}>
            <hr className={styles.Spacer} />
            <div className={styles.ActionIcon} onClick={scrollBelowFold}>
                <img src={ActionIcon} />
            </div>
        </div>
    );
}

function Projects() {
    // TODO: reenable the links once project pages are done
    return (
        <project.ProjectArchive.Component title="Recent Work" showArrow>
            <project.ProjectArchive.Card
                image={CodeX}
                linkTo={Pages.url("Code.X")}
            >
                <p>
                    I lead strategic leadership, portfolio management, and
                    technical curriculum development with{" "}
                    <ExternalLink
                        href="https://www.codedotx.org/"
                        style="Content"
                    >
                        Code.X
                    </ExternalLink>
                    , a global nonprofit that I co-founded
                </p>
            </project.ProjectArchive.Card>
            <project.ProjectArchive.Card
                image={Pear}
                linkTo={Pages.url("Pear Therapeutics")}
            >
                <p>
                    I led service design, product management, web & mobile
                    UI/UX, and research with{" "}
                    <ExternalLink
                        href="https://dtxalliance.org/members/pear-therapeutics/"
                        style="Content"
                    >
                        Pear Therapeutics
                    </ExternalLink>
                    , a pioneering Prescription Digital Therapeutics start-up
                </p>
            </project.ProjectArchive.Card>
            <project.ProjectArchive.Card
                image={Change}
                linkTo={Pages.url("Change Healthcare")}
            >
                <p>
                    I led industry research, web & mobile UI/UX, design systems,
                    and workshop facilitation with{" "}
                    <ExternalLink
                        href="https://www.changehealthcare.com/"
                        style="Content"
                    >
                        Change Healthcare
                    </ExternalLink>
                    , a health tech industry leader
                </p>
            </project.ProjectArchive.Card>
        </project.ProjectArchive.Component>
    );
}

function ProjectArchive() {
    return (
        <div className={styles.ProjectArchive}>
            <project.ProjectArchive.Component title="More Projects">
                <project.ProjectArchive.Card image={Agathos}>
                    <p>
                        I designed web and mobile solutions for physicians
                        (Agathos Engage), improving patient experience through
                        care insights with{" "}
                        <ExternalLink
                            href="https://www.agathos.health/engage"
                            style="Content"
                        >
                            Agathos
                        </ExternalLink>
                    </p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={Nuleaf}>
                    <p>
                        I designed a recipe and grocery shopping mobile app to
                        promote cost transparency and nutritional education for
                        CalFresh users with{" "}
                        <ExternalLink
                            href="https://biodesign.stanford.edu/our-impact/stories/biodesign-next-helps-low-income-californians-eat-healthy.html"
                            style="Content"
                        >
                            Stanford
                        </ExternalLink>
                    </p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={Kheyti}>
                    <p>
                        I conducted field research for ultra-cheap agricultural
                        structure concepts for small farmers in India to scale
                        their businesses with{" "}
                        <ExternalLink
                            href="https://www.nationalgeographic.com/environment/article/telangana-india-agriculture-greenhouses"
                            style="Content"
                        >
                            Kheyti
                        </ExternalLink>
                    </p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={Sonim}>
                    <p>
                        I conducted field research for a concept line expansion
                        of “rugged” work phones for the plumbing and
                        construction markets with{" "}
                        <ExternalLink
                            href="https://www.sonimtech.com/"
                            style="Content"
                        >
                            Sonim Technologies
                        </ExternalLink>
                    </p>
                </project.ProjectArchive.Card>
                <project.ProjectArchive.Card image={Nexus}>
                    <p>
                        I designed and hand-machined a portable abacus lamp out
                        of aluminum and steel, as a concept for an interactive
                        and functional house piece
                    </p>
                </project.ProjectArchive.Card>
            </project.ProjectArchive.Component>
        </div>
    );
}

let spacerHeight = null as number | null;

export default function () {
    const { pathname } = useLocation();

    return (
        <>
            <div id={styles.AboveFold}>
                <Introduction />
                <ActionButton />
            </div>
            <div id={styles.BelowFold}>
                <Projects />
                <ProjectArchive />
            </div>
        </>
    );
}
