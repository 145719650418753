import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import styles from "./About.module.scss";
import { ExternalLink } from "../components/Common";

import Headshot from "../images/2_About/1_Headshot.png";

function FacePhoto() {
    return (
        <div className={cx(styles.Section, styles.FacePhoto)}>
            <div className={styles.FacePhoto__Image}>
                <img src={Headshot} />
            </div>
        </div>
    );
}

function Section(props: PropsWithChildren<{ title: string }>) {
    return (
        <div className={styles.Section}>
            <p className={styles.Section__Title}>{props.title}</p>
            <div className={styles.Section__Content}>{props.children}</div>
        </div>
    );
}

function Introduction() {
    return (
        <div className={styles.Introduction}>
            <FacePhoto />
            <Section title="🌸 Taylor Naomi Sihavong">
                <p>
                    I'm a product lead creating a world where every country has
                    the tools to participate in technology innovation.
                </p>
                <p>
                    I enjoy being tossed in the deep end, learning on the fly,
                    and detangling complex problems during open-ended research
                    initiatives that inform broad or regional change. I'm
                    looking for fast-paced, small teams of passionate people who
                    push my growth.
                </p>
                <p>
                    If you want to psychoanalyze me, I'm an{" "}
                    <ExternalLink
                        href="https://www.integrative9.com/enneagram/introduction/type-4"
                        style="Content"
                    >
                        <b>Enneagram Intense Creative</b>
                    </ExternalLink>{" "}
                    . I've been described as creative yet down to earth,
                    reliable, and purpose-driven.
                </p>
                <p>
                    You can find me slowly circumnavigating the world, with
                    belongings scattered across 🎡 London, 🗽 New York, 🌉 San
                    Francisco, and 🌴 Honolulu.
                </p>
            </Section>
        </div>
    );
}

function Content() {
    return (
        <div className={styles.Content}>
            <Section title="Current">
                <p>
                    I’m a co-founder and president of the California-based
                    nonprofit{" "}
                    <ExternalLink
                        href="https://www.codedotx.org/"
                        style="Content"
                    >
                        <b>Code.X</b>
                    </ExternalLink>
                    , where I provide accessible technology, design, and
                    entrepreneurship education for youth in fragile and
                    conflict-affected regions in partnership with orgs including{" "}
                    <ExternalLink
                        href="https://www.cisco.com/c/en/us/about/csr/impact/cisco-foundation.html"
                        style="Content"
                    >
                        <b>The Cisco Foundation</b>
                    </ExternalLink>
                    ,{" "}
                    <ExternalLink href="https://techstars.org/" style="Content">
                        <b>Techstars Foundation</b>
                    </ExternalLink>
                    , and{" "}
                    <ExternalLink
                        href="https://www.coursera.org/social-impact"
                        style="Content"
                    >
                        <b>Coursera</b>
                    </ExternalLink>
                    .
                </p>
                <p>
                    I am a{" "}
                    <ExternalLink
                        href="https://gratitude-network.org/who-we-support/2024-organizations/"
                        style="Content"
                    >
                        <b>Gratitude Network</b>
                    </ExternalLink>{" "}
                    2024 Fellow for my social entrepreneurship and a{" "}
                    <ExternalLink
                        href="https://www.faspe-ethics.org/design-technology/"
                        style="Content"
                    >
                        <b>FASPE</b>
                    </ExternalLink>{" "}
                    Technology + Design 2024 Fellow for my design leadership.
                </p>
                <p>
                    During rare moments of downtime, I enjoy mentoring budding
                    designers on{" "}
                    <ExternalLink
                        href="https://adplist.org/mentors/taylor-sihavong"
                        style="Content"
                    >
                        <b>ADPList</b>
                    </ExternalLink>{" "}
                    and cluttering my home with various arts and crafts.
                </p>
            </Section>

            <Section title="Previous">
                <p>
                    I was a product design lead pushing exploratory design
                    research and data-driven solutions for US health tech
                    companies including{" "}
                    <ExternalLink
                        href="https://www.statnews.com/2023/09/19/pear-therapeutics-medicaid-masshealth-prescription-apps/"
                        style="Content"
                    >
                        <b>Pear Therapeutics</b>
                    </ExternalLink>
                    ,{" "}
                    <ExternalLink
                        href="https://www.changehealthcare.com/"
                        style="Content"
                    >
                        <b>Change Healthcare</b>
                    </ExternalLink>
                    ,{" "}
                    <ExternalLink
                        href="https://www.agathos.health/"
                        style="Content"
                    >
                        <b>Agathos</b>
                    </ExternalLink>
                    , and{" "}
                    <ExternalLink
                        href="https://www.akidolabs.com/"
                        style="Content"
                    >
                        <b>Akido Labs</b>
                    </ExternalLink>
                    . I led product direction for 20+ live B2B and B2C products
                    spanning web apps, mobile apps, and enterprise software for
                    patients, payers, and healthcare providers.
                </p>
                <p>
                    I was a youth educator and curriculum designer focused on
                    STEM, entrepreneurship, and design thinking, with classrooms
                    of students ranging from 2- to 18-years-old at{" "}
                    <ExternalLink
                        href="https://www.jawwal.ps/en/about-paltel"
                        style="Content"
                    >
                        <b>Paltel Group Foundation</b>
                    </ExternalLink>
                    ,{" "}
                    <ExternalLink
                        href="https://bingschool.stanford.edu/"
                        style="Content"
                    >
                        <b>Bing Nursery School</b>
                    </ExternalLink>
                    , and{" "}
                    <ExternalLink
                        href="https://www.rlsms.k12.hi.us/"
                        style="Content"
                    >
                        <b>Robert Louis Stevenson Middle School</b>
                    </ExternalLink>
                    .
                </p>
                <p>
                    I organized community-focused design research for CalFresh
                    and SNAP users as a NEXT Fellow at the{" "}
                    <ExternalLink
                        href="Stanford Byers Center for Biodesign"
                        style="Content"
                    >
                        <b>Stanford Byers Center for Biodesign</b>
                    </ExternalLink>
                    .
                </p>
                <p>
                    I spent six months with{" "}
                    <ExternalLink
                        href="https://www.kheyti.com/"
                        style="Content"
                    >
                        <b>Kheyti</b>
                    </ExternalLink>{" "}
                    improving the design of agricultural structures for small
                    farmers across India.
                </p>
                <p>
                    I was a quantitative researcher and data analyst exploring
                    Boron-based pharmaceuticals, immunology, space science &
                    astrobiology, political psychology, and cancer prevention at{" "}
                    <ExternalLink
                        href="https://chemh.stanford.edu/"
                        style="Content"
                    >
                        <b>Stanford Sarafan ChEM-H</b>
                    </ExternalLink>
                    ,{" "}
                    <ExternalLink
                        href="https://peterkimlab.stanford.edu/home"
                        style="Content"
                    >
                        <b>Stanford School of Medicine</b>
                    </ExternalLink>
                    ,{" "}
                    <ExternalLink
                        href="https://www.nasa.gov/content/lynn-j-rothschild"
                        style="Content"
                    >
                        <b>NASA's Ames Research Center</b>
                    </ExternalLink>
                    ,{" "}
                    <ExternalLink
                        href="https://pprg.stanford.edu/our-team"
                        style="Content"
                    >
                        <b>Stanford PPRG Lab</b>
                    </ExternalLink>
                    , and the{" "}
                    <ExternalLink
                        href="https://www.uhcancercenter.org/wills-thomas"
                        style="Content"
                    >
                        <b>University of Hawai'i Cancer Center</b>
                    </ExternalLink>
                    .
                </p>
                <p>
                    I began as a two-time{" "}
                    <ExternalLink
                        href="https://www.punahou.edu"
                        style="Content"
                    >
                        <b>Punahou School</b>
                    </ExternalLink>{" "}
                    debate state champion from O'ahu, Hawai'i.
                </p>
            </Section>

            <Section title="Education">
                <p>
                    <b>Stanford University</b>,{" "}
                    <ExternalLink
                        href="https://me.stanford.edu/academics-admissions/undergraduate/majors/bachelor-product-design"
                        style="Content"
                    >
                        B.S. Engineering - Product Design
                    </ExternalLink>
                    , 2018
                </p>
            </Section>

            <Section title="Speaking & Publications">
                <p>
                    <b>World Bank EduTech Podcast</b>,{" "}
                    <ExternalLink
                        href="https://podcasters.spotify.com/pod/show/world-bank-edutech/episodes/Breaking-Barriers-Fostering-Tech-Skills-in-Emerging-Markets-e2hcdbd"
                        style="Content"
                    >
                        “Breaking Barriers: Fostering Tech Skills in Emerging
                        Markets”
                    </ExternalLink>
                    , 2024
                </p>
                <p>
                    <b>PLoS One</b>,{" "}
                    <ExternalLink
                        href="https://collectionsblog.plos.org/igem-report-17-09/"
                        style="Content"
                    >
                        “Engineering a ‘BioBalloon’ for Mid-Atmospheric Sensing”
                    </ExternalLink>
                    , 2017
                </p>
                <p>
                    <b>Stanford Journal of Public Health</b>,{" "}
                    <ExternalLink
                        href="https://web.stanford.edu/group/sjph/cgi-bin/sjphsite/wp-content/uploads/2017/05/SJPH-Volume-6-2016-2017-1.pdf"
                        style="Content"
                    >
                        “Organ Transplantation after Cardiac Death”
                    </ExternalLink>
                    , 2017
                </p>
                <p>
                    <b>Bay Area Maker Faire</b>,{" "}
                    <ExternalLink
                        href="https://makerfaire.com/maker/entry/58067/"
                        style="Content"
                    >
                        Workshop Facilitator
                    </ExternalLink>
                    , 2016
                </p>
            </Section>

            <Section title="Grants & Accolades">
                <p>
                    <b>Forbes</b>,{" "}
                    <ExternalLink
                        href="https://www.forbes.com/profile/codex/"
                        style="Content"
                    >
                        30 Under 30 North America in Education
                    </ExternalLink>
                    , 2025
                </p>
                <p>
                    <b>The Gratitude Network</b>,{" "}
                    <ExternalLink
                        href="https://gratitude-network.org/who-we-support/2024-organizations/"
                        style="Content"
                    >
                        Gratitude Fellow
                    </ExternalLink>
                    , 2024
                </p>
                <p>
                    <b>
                        Fellowships at Auschwitz for the Study of Professional
                        Ethics
                    </b>
                    ,{" "}
                    <ExternalLink
                        href="https://www.faspe-ethics.org/fellows-by-year/"
                        style="Content"
                    >
                        Design and Technology Fellow
                    </ExternalLink>
                    , 2024
                </p>
                <p>
                    <b>The Greater Sum Foundation</b>, March/April Nonprofit
                    Incubator, 2024
                </p>
                <p>
                    <b>ADPList</b>,{" "}
                    <ExternalLink
                        href="https://medium.com/amazing-together/adplists-top-1-mentors-and-rising-stars-november-2023-e0b6eead4262"
                        style="Content"
                    >
                        Top 1% ADPList Mentor in Design
                    </ExternalLink>
                    , 2023
                </p>
                <p>
                    <b>Stanford Young Alumni Opportunity Fund</b>, Grant
                    Funding, 2023
                </p>
                <p>
                    <b>
                        Stanford Chemistry, Engineering, & Medicine for Human
                        Health
                    </b>
                    , ChEM-H Entrepreneurship Award with{" "}
                    <ExternalLink
                        href="https://chemh.stanford.edu/training-education/undergraduate-programs/undergraduate-entrepreneurship-program/funded-projects"
                        style="Content"
                    >
                        Grant Funding
                    </ExternalLink>
                    , 2018
                </p>
                <p>
                    <b>Stanford Student Organizing Committee for the Arts</b>,
                    Grant Funding, 2018
                </p>
                <p>
                    <b>GenHERation</b> and <b>Southwest Airlines</b>, March
                    Design Project Top 10 Finalist, 2017
                </p>
                <p>
                    <b>Stanford Byers Center for Biodesign</b>,{" "}
                    <ExternalLink
                        href="https://biodesign.stanford.edu/our-impact/stories/biodesign-next-helps-low-income-californians-eat-healthy.html"
                        style="Content"
                    >
                        NEXT Fellow
                    </ExternalLink>{" "}
                    with Grant Funding, 2017
                </p>
                <p>
                    <b>Stanford Byers Center for Biodesign</b>, Best Need Award,
                    2017
                </p>
                <p>
                    <b>Stanford Byers Center for Biodesign</b>, Mobile Health
                    Innovation Award, 2016
                </p>
                <p>
                    <b>iGEM Foundation</b>, Gold Medal, 2016
                </p>
                <p>
                    <b>iGEM Foundation</b>, Best Measurement Project, 2016
                </p>
                <p>
                    <b>iGEM Foundation</b>, Best Manufacturing Project
                    Runner-Up, 2016
                </p>
            </Section>

            <Section title="Work Features">
                <p>
                    <b>Code.X</b>,{" "}
                    <ExternalLink
                        href="https://www.codedotx.org/blog/2024/7/19/codex-hosts-online-summer-school-to-support-gazan-high-school-students"
                        style="Content"
                    >
                        "Code.X Hosts Online Summer School to Support Gazan High
                        School Students"
                    </ExternalLink>
                    , 2024
                </p>
                <p>
                    <b>Help Us Help</b>,{" "}
                    <ExternalLink
                        href="Canadian and American Organizations Collaborate to Launch Cutting-Edge Computer Science Education Program for War-Affected and Disadvantaged Youth in Ukraine"
                        style="Content"
                    >
                        Canadian and American Organizations Collaborate to
                        Launch Cutting-Edge Computer Science Education Program
                        for War-Affected and Disadvantaged Youth in Ukraine"
                    </ExternalLink>
                    , 2024
                </p>
                <p>
                    <b>Code.X</b>,{" "}
                    <ExternalLink
                        href="https://www.codedotx.org/blog/2024/1/18/codex-opens-calls-for-youth-program-code-for-egypt"
                        style="Content"
                    >
                        "Code.X Opens Calls for Youth Program “Code for Egypt”
                    </ExternalLink>
                    , 2024
                </p>
                <p>
                    <b>Code.X</b>,{" "}
                    <ExternalLink
                        href="https://www.codedotx.org/blog/2023/09/06/theciscofoundation-announcement"
                        style="Content"
                    >
                        "New collaboration announcement: Code.X and the Cisco
                        Foundation join forces to bring technology education to
                        youth in West Bank and Gaza"
                    </ExternalLink>
                    , 2023
                </p>
                <p>
                    <b>Amideast</b>,{" "}
                    <ExternalLink
                        href="https://www.amideast.org/news-resources/newsroom/amideast-codex-launch-youth-coding-program-in-gaza"
                        style="Content"
                    >
                        "Amideast, Code.X Launch Youth Coding Program in Gaza"
                    </ExternalLink>
                    , 2023
                </p>
                <p>
                    <b>ABC News Albania</b>,{" "}
                    <ExternalLink
                        href="https://abcnews.al/?p=1318232"
                        style="Content"
                    >
                        “Korça hosts "Code for Albania", the summer camp that
                        brought together 100 children from all over the country
                        and 10 instructors from the USA”
                    </ExternalLink>
                    , 2022
                </p>
                <p>
                    <b>This Week in Palestine</b>,{" "}
                    <ExternalLink
                        href="https://thisweekinpalestine.com/paltel-group-csr/"
                        style="Content"
                    >
                        "A Leading Model in Sustainable Development"
                    </ExternalLink>
                    , 2022
                </p>
                <p>
                    <b>Friends of UNFPA</b>,{" "}
                    <ExternalLink
                        href="https://www.friendsofunfpa.org/coding-in-palestine-how-tech-education-keeps-girls-safe-and-expands-opportunities/"
                        style="Content"
                    >
                        “Coding in Palestine: How Tech Education Keeps Girls
                        Safe and Expands Opportunities”
                    </ExternalLink>
                    , 2021
                </p>
                <p>
                    <b>National Geographic</b>,{" "}
                    <ExternalLink
                        href="https://www.nationalgeographic.com/environment/article/telangana-india-agriculture-greenhouses"
                        style="Content"
                    >
                        “Innovative Greenhouses Help Farmers Adapt to Climate
                        Change”
                    </ExternalLink>
                    , 2018
                </p>
                <p>
                    <b>Stanford Byers Center for Biodesign</b>,{" "}
                    <ExternalLink
                        href="https://biodesign.stanford.edu/our-impact/stories/biodesign-next-helps-low-income-californians-eat-healthy.html"
                        style="Content"
                    >
                        “Biodesign NEXT Facilitates Student Plans to Help
                        Low-Income Californians Eat Healthy”
                    </ExternalLink>
                    , 2017
                </p>
            </Section>

            <Section title="Skills & Tools">
                <p>
                    <b>Leadership & Management</b> / Cross-functional team
                    management, project management, client and partner
                    relations, workshop design & facilitation
                </p>
                <p>
                    <b>Design</b> / Insight translation, rapid
                    prototyping/wireframing (physical and digital), visual and
                    UI/UX, usability testing, design systems and information
                    design /{" "}
                    <i>
                        Adobe Creative Cloud (Photoshop, Illustrator, InDesign,
                        XD), Figma
                    </i>
                </p>
                <p>
                    <b>Product</b> / Customer feedback, product strategy and
                    prioritization, requirements gathering, product roadmap
                    development, resource management /{" "}
                    <i>
                        Atlassian (Confluence, Jira, Trello), Lucidchart,
                        Microsoft 365 (Excel, PowerPoint, Word)
                    </i>
                </p>
                <p>
                    <b>Research</b> / Qualitative, quantitative, and hybrid
                    research (remote and on-site), AI/GPT prompting, trend
                    analysis, speculative design, research scoping and planning,
                    participant recruiting, needfinding and design thinking
                    processes, journey maps and user personas, insights
                    storytelling /{" "}
                    <i>
                        Airtable, FigJam, Qualtrics, Miro, Mural, User
                        Interviews
                    </i>
                </p>
                <p>
                    <b>Engineering</b> / analytical problem-solving,
                    fabrication, Python (basic), HTML/CSS (basic)
                </p>
                <p>
                    <b>Language</b> / English (native), Japanese (basic)
                </p>
            </Section>

            <Section title="Certifications">
                <p>
                    <b>Google</b>,{" "}
                    <ExternalLink
                        href="https://www.coursera.org/professional-certificates/google-project-management"
                        style="Content"
                    >
                        Project Management Certificate
                    </ExternalLink>
                    , 2024
                </p>
                <p>
                    <b>DeepLearning.AI</b>,{" "}
                    <ExternalLink
                        href="https://www.deeplearning.ai/courses/ai-for-good/"
                        style="Content"
                    >
                        AI For Good Specialization (Public Health, Climate
                        Change, Disaster Management)
                    </ExternalLink>
                    , 2024
                </p>
                <p>
                    <b>
                        Stanford University Center for Professional Development
                    </b>
                    ,{" "}
                    <ExternalLink
                        href="https://ethicsinsociety.stanford.edu/tech-ethics/career-pathways-professional-development/ethics-technology-and-public-policy-for-practitioners"
                        style="Content"
                    >
                        Ethics, Technology, and Public Policy for Practitioners
                    </ExternalLink>
                    , 2023
                </p>
                <p>
                    <b>RETHINK HQ</b>,{" "}
                    <ExternalLink
                        href="https://www.rethinkhq.com/programs/storytelling-to-influence-change"
                        style="Content"
                    >
                        Storytelling to Influence Change
                    </ExternalLink>
                    , 2023
                </p>
                <p>
                    <b>Qualtrics</b>,{" "}
                    <ExternalLink
                        href="https://basecamp.qualtrics.com/page/corexm-expert-certification"
                        style="Content"
                    >
                        CoreXM Expert Certification
                    </ExternalLink>
                    , 2021
                </p>
                <p>
                    <b>Qualtrics</b>,{" "}
                    <ExternalLink
                        href="https://basecamp.qualtrics.com/page/qualtrics-platform-essentials-certification"
                        style="Content"
                    >
                        Platform Essentials Certification
                    </ExternalLink>
                    , 2021
                </p>
            </Section>
        </div>
    );
}

export default function () {
    return (
        <>
            <Introduction />
            <Content />
        </>
    );
}
